<template>
  <div>
    <title-bar :items="title">
      <template #buttonsleft>
        <b-dropdown aria-role="menu" class="mr-2">
          <template #trigger>
            <button-header
              icon-left="ellipsis-v"
            >
              Páginas
            </button-header>
          </template>
          <b-dropdown-item 
            aria-role="listitem" 
            has-link 
            v-for="page in dashboard.categoria.dashboards"
            :key="page.id" >
            <router-link :to="`/form/1623469803663/${page.id}`" active>
              <b-icon icon="file"></b-icon> {{page.nombre}} {{page.descripcion ? ` - ${page.descripcion}` : ``}}
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item 
            aria-role="listitem"
            @click="handleAddPage">
            <b-icon icon="plus"></b-icon> Agregar página
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown aria-role="list" class="mr-2">
          <template #trigger>
            <button-header
              icon-left="ellipsis-v"
            >
              Acciones
            </button-header>
          </template>
          <b-dropdown-item aria-role="listitem" has-link >
            <router-link :to="toGrafico"><b-icon icon="plus"></b-icon> Nuevo gráfico</router-link>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" has-link >
            <router-link :to="toMedidor"><b-icon icon="plus"></b-icon> Nuevo medidor</router-link>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" has-link >
            <router-link :to="toNumero"><b-icon icon="plus"></b-icon> Nuevo número</router-link>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" has-link >
            <router-link :to="toFiltro"><b-icon icon="filter"></b-icon> Editar filtros</router-link>
          </b-dropdown-item>
          <b-dropdown-item separator></b-dropdown-item> 
          <!-- <b-dropdown-item aria-role="listitem"><b-icon icon="share-square"></b-icon> Compartir acceso</b-dropdown-item> -->
          <b-dropdown-item 
            aria-role="listitem"
            @click="handleCloneDashboard">
            <b-icon icon="clone"></b-icon> Clonar página
          </b-dropdown-item>
          <b-dropdown-item 
            aria-role="listitem"
            @click="handleLinkShareDashboard">
            <b-icon icon="link"></b-icon> Obtener link para compartir
          </b-dropdown-item>
          <b-dropdown-item 
            aria-role="listitem"
            @click="handleEditDashboard">
            <b-icon icon="edit"></b-icon> Editar título
          </b-dropdown-item>
          <b-dropdown-item 
            v-if="dashboard.categoria.dashboards.length > 1"
            aria-role="listitem"
            @click="handleDeleteDashboard">
            <b-icon icon="trash"></b-icon> Eliminar página
          </b-dropdown-item>
        </b-dropdown>  
      </template>
      <template #buttons>
        <p class="has-text-weight-light is-italic">Actualizado el {{dashboard.updated_at}}</p>
      </template>
    </title-bar>
    <div class="is-clearfix mt-2">
      <ListPanel />
    </div>
    <share-link-dashboard-modal
      v-model="modalShareLinkDash"
      :share_limit_date="dashboard.attrs.share_limit_date" />
    <ModalFormDashboard />
  </div>
</template>
<script>
import { nameStoreForm } from "@/utils/helpers";
const name_store = nameStoreForm( require.context("../", false, /\.name$/i) );
// 
import ListPanel from "../components/index/paneles/ListPanel";
import ShareLinkDashboardModal from '../components/index/modals/ShareLinkDashboardModal.vue';
import ModalFormDashboard from "@/modules/form/1617124496702/components/nuevo/Modal";
export default {
  components: {
    ListPanel,
    ShareLinkDashboardModal,
    ModalFormDashboard,
  },
  data(){
    return {
      modalShareLinkDash: false,
    }
  },
  computed:{
    dashboard(){
      return this.$store.getters[`form_1617124496702/row`]
    },
    getters(){
      return key => this.$store.getters[`${name_store}/${key}`]
    },
    toGrafico(){
      return this.getters(`toGrafico`)(this.$route.params.id)
    },
    toMedidor(){
      return this.getters(`toMedidor`)(this.$route.params.id)
    },
    toNumero(){
      return this.getters(`toNumero`)(this.$route.params.id)
    },
    toFiltro(){
      return this.getters(`toFiltro`)(this.$route.params.id)
    },
    title(){
      return this.$store.getters[`form_1617124496702/title_bar`]()
    }
  },
  methods: {
    async handleCloneDashboard(){
      if(confirm("Se creará una copia de este página. Desea continuar?")){
        const data = await this.$store.dispatch(`form_1617124496702/clone`, this.dashboard.id);
        if(data){
          this.$router.push({name: `1623469803663` , params: {id: data.id}})
        }
      }
    },
    async handleDeleteDashboard(){
      if(confirm(`Se eliminará la "${this.dashboard.nombre}". Desea continuar?`)){
        const data = await this.$store.dispatch(`form_1617124496702/destroy`, this.dashboard.id);
        if(data){
          this.$router.push(`/form/1623469803663/${data.id}`)
        } else {
          this.$router.push(`/form/1617206897966`)
        }
      }
    },
    handleEditDashboard(){
      this.$store.commit(`form_1617124496702/SET_ROW_EDIT`, this.dashboard)
      this.$store.commit(`form_1617124496702/SET_MODAL`,{form: true})
    },
    async handleAddPage(){
      const data = await this.$store.dispatch(`form_1617124496702/save`, {
        id_padre: this.dashboard.id_padre
      })
      if(data){
        this.$router.push(`/form/1623469803663/${data.id}`)
      }
    },
    handleLinkShareDashboard(){
      this.modalShareLinkDash = true
    }
  }
};
</script>