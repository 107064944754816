<template>
  <modal-base 
    v-model="show" 
    :title="title"
    @after-enter="onMain">
    <Form 
      v-model="form"
      @oncancel="show = !show"
      @onsuccess="show = !show" />
  </modal-base>
</template>
<script>
import Form from "./Form.vue"
import { nameStoreForm } from "@/utils/helpers";
const name_store = nameStoreForm( require.context("../../", false, /\.name$/i) );
// 
export default {
  components:{
    Form,
  },
  data(){
    return {
      form: {}
    }
  },
  computed:{
    show:{
      get(){
        return this.$store.getters[`${name_store}/modal`].form || false;
      },
      set(e){
        this.$store.commit(`${name_store}/SET_MODAL`, {form: e})
      }
    },
    categorie(){
      const e = this.$store.getters[`form_1617206897966/getSelected`](`for_dash`)
      return e && e.length && e[0];
    },
    title(){
      return (!this.rowEdit ? `Crear página` : `Editar ${this.rowEdit.nombre}`)
    },
    rowEdit(){
      return this.$store.getters[`${name_store}/rowEdit`]
    }
  },
  methods: {
    onMain(){
      if(this.rowEdit){
        this.form = {...this.rowEdit}
      } else {
        this.form = {}
        if(this.categorie) {
          this.form.id_padre = this.categorie.id
        }
      }
    }
  }
}
</script>