<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <div class="columns is-flex-wrap-wrap">
      <div class="column is-12">
        <input-base
          rules=""
          focus
          label="Titulo de la página"
          maxlength="250"
          placeholder="Titulo de la página"
          v-model="form.descripcion"
        />
      </div>
    </div>
    <p class="is-pulled-right is-italic" v-if="form.updated_at">
      <span class="has-text-weight-semibold">Actualizado:</span> {{form.updated_at}}
    </p>
    <div>
      <b-button
        native-type="submit"
        type="is-primary"
        icon-left="save"
        :loading="loading"
        class="mt-3 mr-3"
        >Guardar</b-button
      >
      <b-button
        type="is-secondary"
        icon-left="times"
        :loading="loading"
        class="mt-3"
        @click="() => $emit(`oncancel`)"
        >Cancelar</b-button
      >
    </div>
  </form-observer>
</template>

<script>
import { nameStoreForm } from "@/utils/helpers";
const name_store = nameStoreForm( require.context("../../", false, /\.name$/i) );

export default {
  props:{
    value:{
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  computed:{
    form:{
      get(){
        return this.value || {}
      },
      set(e){
        this.$emit("input", e)
      }
    }
  },
  watch:{
    "form.id":{
      handler(e){
        if(!e){
          this.defaultData()
        }
      },
      immediate: true,
    }
  },
  methods: {
    defaultData(){
      if(this.$refs.observer){
        // this.$refs.observer.reset()
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      //
      this.loading = true;
      const row = await this.$store.dispatch(`${name_store}/save`, this.form)
      if(row){
        this.$store.dispatch(
          "app/setToastSuccess",
          `Se acaba de guardar los cambios de : "${row.nombre}"`
        );
        if(!this.form.id) {
          this.form = {}
          this.defaultData()
        }
        this.$emit("onsuccess")
      }
      this.loading = false;
    },
  },
};
</script>
