<template>
  <modal-base 
    v-model="show" 
    :title="title">
    <form-observer ref="observer" id="form" @submit.prevent="onSubmit">
      <div class="columns is-flex-wrap-wrap">
        <div class="column is-12">
          <input-base
            label="Comparte este link para que las personas puedan ver el reporte"
            placeholder="URL para compartir"
            v-model="form.url"
            readonly
          />
        </div>
        <div class="column is-12">
          <date-time-picker-base
            rules="required"
            inline
            label="Este link será valido hasta el día:"
            v-model="form.share_limit_date"
          />
        </div>
      </div>
    </form-observer>
    <template #footer>
      <b-button
        native-type="submit"
        form="form"
        type="is-primary"
        icon-left="save"
        :loading="loading"
        class="mt-3 mr-3"
        >Guardar</b-button
      >
      <b-button
        type="is-secondary"
        icon-left="times"
        :loading="loading"
        class="mt-3"
        @click="show = false"
        >Cancelar</b-button
      >
    </template>
  </modal-base>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    share_limit_date:{
      type: [Number, Date],
      default: () => new Date,
    }
  },
  data(){
    return {
      form: {},
      loading: false,
    }
  },
  computed:{
    dashboard(){
      return this.$store.getters[`form_1617124496702/row`]
    },
    show:{
      get(){
        return this.value;
      },
      set(e){
        this.$emit(`input`, e)
      }
    },
    title(){
      return `Compartir acceso público por link`
    },
  },
  created() {
    this.form = {
      id: this.dashboard.id,
      url: `${window.location.origin}/share/${this.dashboard.codigo}`,
      share_limit_date: this.share_limit_date,
    }
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      //
      this.loading = true;
      const row = await this.$store.dispatch(`form_1617124496702/save`, this.form)
      if(row){
        this.$store.dispatch(
          "app/setToastSuccess",
          `Se acaba de guardar los cambios "Obtener link para compartir"`
        );
        this.show = false
        this.$emit("onsuccess")
      }
      this.loading = false;
    },
  }
}
</script>